import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom"

import Home from "../Home/Home"
import Contact from "../Contact/Contact"
import Courses from "../Courses/Courses"
import Calendar from "../Calendar/Calendar"
import About from "../About/About"
import "../../styles/global.scss"
import "./App.scss";

import Logo from "./assets/logo1-01.png"

function App() {
  return (
    <div className = 'App'>
      <Router>
        <Link to = "/">
          <img src = {Logo} alt = "autumn grove academy logo" className = "logo" />
        </Link>
        <nav>
          <div className = "navigation row around-xs">
            <div className = 'navItem'>
              <Link to = "/about">About Us</Link>
            </div>
            <div className = 'navItem'>
              <Link to = "/courses">Courses</Link>
            </div>
            <div className = 'navItem'>
              <Link to = "/calendar">Calendar</Link>
            </div>
            <div className = 'navItem'>
              <Link to = "/contact">Contact Us</Link>
            </div>
            <div className = 'navItem'>
              <a href = "http://student.autumngroveacademy.com/" target = "_blank">Student Portal</a>
            </div>
          </div>
        </nav>
        <Switch>
          <Route path = "/contact" component = { Contact }/>
          <Route path = "/courses" component = { Courses }/>
          <Route path = "/about" component = { About }/>
          <Route path = "/calendar" component = { Calendar } />
          <Route path = "/" component = { Home } />
        </Switch>
      </Router>
    </div>
  );
}

export default App;