import React, { useState, useEffect } from 'react'
import { client } from "../../helpers/contentful"

import Row from "../Contentful/Row"
const About = () => {
  const [ pageDetails, setPageDetails ] = useState({})

  useEffect(() => {
    client.getEntry('5tBOCZBRkrt2l2km3Yu7Xj').then(page => {
      setPageDetails(page.fields)
    })
  }, [ ])
  
  return (
    <>
      <div className = "About">
        { pageDetails.hero &&
          <div className = "hero"
            style = {{
              background: `url(${pageDetails.hero.fields.heroImage.fields.file.url}) center bottom / cover no-repeat`
            }}
          >
            <h1>{pageDetails.hero.fields.heroTitle || ''}</h1>
          </div>
        }
      </div>
      <div className = "container">
        {
          (pageDetails.rows || []).map((row, _i) => {
            return (
              <Row component = {row.sys} key = {_i} />
            )
          })
        }
      </div>
    </>
  )
}

export default About