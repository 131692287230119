import * as Contentful from 'contentful'

export const client = Contentful.createClient({
  space: "szu2iqbtz726",
  accessToken: "CkxBHa4zB0pBAlgTXfLDCn4_0C5Sfm1Z_cvHZcsS-XA"
})

export const getPage = async (pageId) => {
  console.log(pageId)
  try {
    client.getEntry(pageId).then(page => {
      console.log(page)
      return page
    })
  } catch(error) {
    console.error(error)
  }
}
