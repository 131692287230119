import React, { useState, useEffect } from 'react'
import { client } from "../../helpers/contentful"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Row = ({component}) => {
  const [ body, setBody ] = useState({fields:{rowContents: []}})
  
  const getDetails = async () => {
    client.getEntry((component.id || '')).then((cards) => {
      setBody(cards)
    })
  }

  useEffect(() => {
    getDetails()
  }, [ ])

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <a href = {node.data.target.fields.file.url} target = "_blank">{node.data.target.fields.title}</a>
        )
      }
    }
  }
  return (
    <div className = "row between-xs">
      {
        body.fields.rowContents.map((content, _i) => {
          return (
            <div className = {
                `${content.sys.contentType.sys.id === 'oneThird' ? 'col-xs-12 col-md-3 card' : ''}
                ${content.sys.contentType.sys.id === 'fullRow' ? 'col-xs-12' : ''}
                ${content.sys.contentType.sys.id === 'halfRow' ? 'col-xs-12 col-md-5 card' : ''}`
              }
              key = {_i}
            >
              {
                documentToReactComponents(content.fields.textContent, options)
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default Row